import * as React from 'react';
import { useTranslation } from 'next-i18next';
import Image from '@SnackatCafe/snackat-ui/dist/Image';
import {
  Autocomplete,
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';

import useMobile from 'src/hooks/useMobile';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import Spinner from '@SnackatCafe/snackat-ui/dist/Spinner';
import { LatLng } from 'src/modules/address/types';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import { LIBRARIES } from 'src/modules/address/constants';
interface MapVariables {
  center: LatLng;
  zoomWarn?: boolean;
  currentLocation?: boolean;
  onLoad?: (map) => void;
  options?: google.maps.MapOptions;
  onZoomChanged?: (map) => void;
  autocomplete?: boolean;
  handleAutocompleteChange?: (event: any) => void;
  handlePlacesChanged?: (query?: string) => void;
  style?: any;
  queryLatLng?: any;
}
const Map: React.FC<MapVariables> = ({
  center,
  style,
  options,
  onLoad,
  currentLocation,
  autocomplete,
  handleAutocompleteChange,
  handlePlacesChanged,
  queryLatLng,
  zoomWarn,
  onZoomChanged,
}) => {
  const { t } = useTranslation('location');

  const { language } = useTranslationContext();

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GEOCODE_API,
    version: 'weekly',
    libraries: LIBRARIES,
    language,
  });

  const [map, setMap] = React.useState(null);
  const [newCenter, setNewCenter] = React.useState(center);
  const [autocompleteOptions, setAutocompleteOptions] =
    React.useState<google.maps.places.ComponentRestrictions>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const isMobile = useMobile();
  const { userCountry } = useLocationContext();

  const mapStyle = {
    width: isMobile ? '90vw' : '100%',
    height: isMobile ? '25vh' : '200px',
    margin: '0 auto',
    border: '1px solid #dddd',
    borderRadius: '5px',
  };

  const locateMe = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        map.panTo({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => null
    );
  };

  const onLoadMap = React.useCallback(function callback(map) {
    if (center?.lat) {
      map?.moveCamera({ center });
      setNewCenter(center);
    }
    setMap(map);
    onLoad && onLoad(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const handleBoundsChanged = () => {
    const mapCenter = map?.getCenter();
    setNewCenter(mapCenter);
  };

  React.useEffect(() => {
    if (userCountry) {
      setAutocompleteOptions({ country: userCountry });
    }
  }, [userCountry]);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const queryLat = queryLatLng?.getPlace()?.geometry?.location?.lat();
      if (queryLat) {
        handlePlacesChanged(queryLatLng);
      }
      setLoading(false);
    }, 1000);
  }, [queryLatLng]);

  React.useEffect(() => {
    if (center?.lat) {
      handleBoundsChanged();
    }
  }, [center]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={style ? style : mapStyle}
      zoom={14}
      onLoad={onLoadMap}
      onUnmount={onUnmount}
      center={center}
      onBoundsChanged={currentLocation && handleBoundsChanged}
      options={{
        ...options,
        clickableIcons: false,
        disableDefaultUI: true,
        fullscreenControl: isMobile,
      }}
      onZoomChanged={() => onZoomChanged && onZoomChanged(map)}
    >
      <div className=" absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
        <Spinner size={80} hide={!loading} className="text-primary-500" />
      </div>
      <Marker
        position={newCenter}
        clickable={false}
        icon={{
          url: '/images/location-pin-1.svg',
          scaledSize: new window.google.maps.Size(40, 40),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(20, 40),
        }}
      >
        {zoomWarn && (
          <InfoWindow>
            <div>{t('map.zoomWarn')}</div>
          </InfoWindow>
        )}
      </Marker>
      {currentLocation && (
        <div onClick={locateMe}>
          <Image
            src="/images/locate-mp.svg"
            width={isMobile ? '45px' : '50px'}
            alt="compass icon"
            className="absolute bottom-4 left-4 cursor-pointer bg-white p-2 shadow"
          />
        </div>
      )}
      {autocomplete && (
        <Autocomplete
          onLoad={handleAutocompleteChange}
          onPlaceChanged={handlePlacesChanged}
          restrictions={autocompleteOptions}
          fields={['name', 'formatted_address', 'place_id', 'geometry']}
        >
          <input
            type="text"
            placeholder={t('map.input.placeholder')}
            className="absolute top-4 left-1/2 h-12 w-3/4 -translate-x-1/2 rounded border border-lines-500 bg-white px-2 shadow-md outline-none placeholder:text-base focus:border-secondary-500"
          />
        </Autocomplete>
      )}
    </GoogleMap>
  ) : (
    <div className=" absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
      <Spinner size={80} hide={!loading} className="text-primary-500" />
    </div>
  );
};
export default React.memo(Map);
