const getBranchId = (lat, lng, geoData): Record<string, number> => {
  let branchId = null;
  let deliveryPrice = null;
  geoData?.features?.forEach(feature => {
    const paths = feature?.geometry?.coordinates[0]?.map(
      point => new google.maps.LatLng(point[1], point[0])
    );
    const poly = new google.maps.Polygon({
      paths,
    });
    const isExisted = google?.maps?.geometry?.poly?.containsLocation(
      new google.maps.LatLng(lat, lng),
      poly
    );
    if (isExisted) {
      branchId = feature?.properties?.branchId;
      deliveryPrice = feature?.properties?.price;
    }
  });
  return { branchId, deliveryPrice };
};

export default getBranchId;
