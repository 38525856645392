import * as React from 'react';
import { getGeocode } from 'use-places-autocomplete';
import { formatMapAddress } from 'src/utils/formatMapAddress';

const useGetGeocode = () => {
  return React.useCallback(async function geocode(
    request: google.maps.GeocoderRequest,
    language,
    setLocation,
    setCity,
    setArea,
    setCanDeliver,
    name?: string
  ) {
    try {
      const results = await getGeocode(request);
      const [routeAddress] = results.filter(
        res =>
          res.types.includes('point_of_interest') ||
          res.types.includes('establishment') ||
          res.types.includes('street_address') ||
          res.types.includes('route')
      );

      const selectedAddress = routeAddress ? routeAddress : results[0];
      const address = formatMapAddress(selectedAddress?.formatted_address);

      setLocation(name ? name + ' - ' + address : address);
      const [area] = selectedAddress.address_components.filter(
        res =>
          res.types.includes('sublocality') ||
          res.types.includes('point_of_interest') ||
          res.types.includes('establishment') ||
          res.types.includes('neighborhood')
      );
      const [routeArea] = selectedAddress.address_components.filter(
        res => res.types.includes('route') || res.types.includes('plus_code')
      );
      const selectedArea = area?.short_name
        ? area?.short_name
        : routeArea?.short_name;
      const [city] = selectedAddress.address_components.filter(res =>
        res.types.includes('locality')
          ? res.types.includes('locality')
          : res.types.includes('administrative_area_level_1')
      );
      setArea({
        name: language === 'en' ? selectedArea : '',
        arName: language === 'ar' ? selectedArea : '',
        language,
      });
      setCity({
        name: language === 'en' ? city.short_name : '',
        arName: language === 'ar' ? city.short_name : '',
        language,
      });
    } catch (error) {
      setCanDeliver(false);
      console.info('😱 Error: ', error);
    }
  },
  []);
};
export default useGetGeocode;
