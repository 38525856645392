import { Transition } from '@headlessui/react';
import * as React from 'react';

interface LinearAnimationProps {
  show: boolean;
  movement?: 'x' | 'y';
  forward?: boolean;
  className?: string;
  enterDuration?: number;
  leaveDuration?: number;
  children: any[] | React.ReactNode;
}

const LinearAnimation: React.FC<LinearAnimationProps> = props => {
  const {
    show,
    forward = true,
    movement = 'x',
    className = '',
    enterDuration = 200,
    leaveDuration = 100,
  } = props;
  let { children } = props;

  if (!Array.isArray(children)) children = [children];

  return (
    <Transition as="div" appear show={show} className={className}>
      {(children as any[])
        .reduce(
          (acc, item) => (item.length ? [...acc, ...item] : [...acc, item]),
          []
        )
        .map((child, i) => {
          return (
            <Transition.Child
              key={i}
              as="div"
              enter={
                enterDuration
                  ? `transition ease-out duration-${enterDuration}`
                  : ''
              }
              enterFrom={`transform opacity-0 ${
                forward
                  ? `-translate-${movement}-20`
                  : `translate-${movement}-20`
              }`}
              enterTo={`transform opacity-100 translate-${movement}-0`}
              leave={
                leaveDuration
                  ? `transition ease-in duration-${leaveDuration}`
                  : ''
              }
              leaveFrom={`transform opacity-100 translate-${movement}-0`}
              leaveTo={`transform opacity-0 ${
                forward
                  ? `-translate-${movement}-20`
                  : `translate-${movement}-20`
              }`}
              style={{ transitionDelay: `${i * 100}ms` }}
            >
              {child}
            </Transition.Child>
          );
        })}
    </Transition>
  );
};

export default LinearAnimation;
