import * as React from 'react';
import { deliveryCountries } from '../deliveryCountries';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import useGetGeocode from './useGetGeocode';

const useLocationExistsOnMapData = () => {
  const { setAreaPrice, setMapPin } = useLocationContext();
  const geoCode = useGetGeocode();
  return React.useCallback(
    (
      { lat, lng },
      map,
      setCanDeliver,
      language,
      setLocation,
      setCity,
      setArea,
      name?: string,
      setBranchId?: any
    ) => {
      const hasBranchId = setBranchId !== undefined;
      setCanDeliver(false);
      hasBranchId &&
        map?.data?.forEach(feature => {
          const countryCode = feature?.getProperty('code');
          if (countryCode) {
            map?.data?.remove(feature);
          }
        });
      map?.data?.forEach(feature => {
        const polyGeo = feature?.getGeometry();
        const isMultiPolygon = polyGeo?.g?.length > 1;
        if (isMultiPolygon) {
          polyGeo?.g?.map(polygon => {
            const poly = new google.maps.Polygon({
              paths: polygon?.getAt(0)?.getArray(),
              map,
              clickable: false,
              fillOpacity: 0,
              strokeOpacity: 0,
            });

            const isExisted = google?.maps?.geometry?.poly?.containsLocation(
              { lat, lng },
              poly
            );
            if (isExisted) {
              setCanDeliver(true);
              geoCode(
                { location: { lat, lng }, language },
                language,
                setLocation,
                setCity,
                setArea,
                setCanDeliver,
                name
              );
              map.moveCamera({ center: { lat, lng } });
              setAreaPrice(feature?.getProperty('price'));
              setMapPin({ lat, lng });
              hasBranchId && setBranchId([feature?.getProperty('branchId')]);
            }
          });
        } else {
          const poly = new google.maps.Polygon({
            paths: polyGeo?.getAt(0)?.getArray(),
            map,
            clickable: false,
            fillOpacity: 0,
            strokeOpacity: 0,
          });
          const isExisted = google?.maps?.geometry?.poly?.containsLocation(
            { lat, lng },
            poly
          );
          if (isExisted) {
            setCanDeliver(true);
            geoCode(
              { location: { lat, lng }, language },
              language,
              setLocation,
              setCity,
              setArea,
              setCanDeliver,
              name
            );
            map?.moveCamera({ center: { lat, lng } });
            setAreaPrice(feature?.getProperty('price'));
            setMapPin({ lat, lng });
            hasBranchId && setBranchId([feature?.getProperty('branchId')]);
          }
        }
      });
      hasBranchId && map?.data?.addGeoJson(deliveryCountries);
    },
    []
  );
};
export default useLocationExistsOnMapData;
