import * as React from 'react';
import Dialog from '@SnackatCafe/snackat-ui/dist/Dialog';
import type { DialogProps, DialogComposition } from '@SnackatCafe/snackat-ui';

import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import LinearAnimation from 'src/animations/LinearAnimation/LinearAnimation';

const DialoG: React.FC<DialogProps> & DialogComposition = (
  props: DialogProps
) => {
  const { children, ...rest } = props;

  const { isRTL } = useTranslationContext();

  return (
    <Dialog {...rest}>
      <LinearAnimation show leaveDuration={0}>
        <div dir={isRTL ? 'rtl' : 'ltr'}>
          <div className="ltr:font-ltr rtl:font-rtl">{children}</div>
        </div>
      </LinearAnimation>
    </Dialog>
  );
};

DialoG.Header = Dialog.Header;
DialoG.Body = Dialog.Body;
DialoG.Actions = Dialog.Actions;

export default DialoG;
