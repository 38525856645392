export const formatMapAddress = (address: string) => {
  const formattedAddress = address
    .split(' ')
    .join(',')
    .split(',')
    .filter(i => !i.includes('+'))
    .join(' ');

  return formattedAddress;
};
