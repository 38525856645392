export const deliveryCountries = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: 'Saudi Arabia',
        code: 'SA',
        group: 'Countries',
      },
      geometry: {
        coordinates: [
          [
            [42.779332, 16.347891],
            [42.649573, 16.774635],
            [42.347989, 17.075806],
            [42.270888, 17.474722],
            [41.754382, 17.833046],
            [41.221391, 18.6716],
            [40.939341, 19.486485],
            [40.247652, 20.174635],
            [39.801685, 20.338862],
            [39.139399, 21.291905],
            [39.023696, 21.986875],
            [39.066329, 22.579656],
            [38.492772, 23.688451],
            [38.02386, 24.078686],
            [37.483635, 24.285495],
            [37.154818, 24.858483],
            [37.209491, 25.084542],
            [36.931627, 25.602959],
            [36.639604, 25.826228],
            [36.249137, 26.570136],
            [35.640182, 27.37652],
            [35.130187, 28.063352],
            [34.632336, 28.058546],
            [34.787779, 28.607427],
            [34.83222, 28.957483],
            [34.956037, 29.356555],
            [36.068941, 29.197495],
            [36.501214, 29.505254],
            [36.740528, 29.865283],
            [37.503582, 30.003776],
            [37.66812, 30.338665],
            [37.998849, 30.5085],
            [37.002166, 31.508413],
            [39.004886, 32.010217],
            [39.195468, 32.161009],
            [40.41888538032004, 31.9421006828862],
            [42.128842842126595, 31.085296108188555],
            [44.74445439153184, 29.18906560961762],
            [46.418169184492015, 29.062608520719166],
            [46.56072434102839, 29.106005611712522],
            [47.47146329482783, 29.000482767496614],
            [47.58167361367617, 28.852960074492955],
            [47.708851, 28.526063],
            [48.44831314564023, 28.53502186184571],
            [48.87132367101379, 27.93976009668941],
            [49.299554, 27.577784750298957],
            [49.64815936625599, 27.359676605611778],
            [50.242040443612524, 26.721685905135818],
            [50.24679085647536, 26.260953502363503],
            [50.151141898415005, 25.94039033571529],
            [50.29960462907503, 25.63678052958121],
            [50.52340395806217, 25.394394107029026],
            [50.660557, 24.999896],
            [50.810108, 24.754743],
            [50.933684741812215, 24.539423429022108],
            [51.00150405956896, 24.49885661856461],
            [51.106905821721966, 24.469450087641714],
            [51.31688933000487, 24.48597185277113],
            [51.36828267700082, 24.52408547852493],
            [51.413996095799504, 24.5971380711791],
            [51.40168153833021, 24.62621294293246],
            [51.500023751478345, 24.64355593728257],
            [51.57815705631344, 24.39704848015093],
            [51.589391823986006, 24.25021104327073],
            [51.5908657868904, 24.126733408295227],
            [52.582778624141724, 22.935382978972413],
            [55.138762296773315, 22.630268589437176],
            [55.2115856326378, 22.704558838220105],
            [55.666659, 22.000001],
            [54.999982, 19.999994],
            [52.00001, 19.000003],
            [49.116672, 18.616668],
            [48.183344, 18.166669],
            [47.616897251885646, 17.469966118444948],
            [47.45865337782847, 17.108471394474485],
            [47.18903102377409, 16.952149373491622],
            [47.000005, 16.949999],
            [46.749994, 17.283338],
            [46.366659, 17.233315],
            [45.399999, 17.333335],
            [45.216651, 17.433329],
            [44.062613, 17.410359],
            [43.791519, 17.319977],
            [43.380794, 17.579987],
            [43.115798, 17.08844],
            [43.218375, 16.66689],
            [42.779332, 16.347891],
          ],
        ],
        type: 'Polygon',
      },
    },
    {
      type: 'Feature',
      properties: { name: 'Qatar', code: 'QA', group: 'Countries' },
      geometry: {
        coordinates: [
          [
            [50.806061432878025, 24.755661681318312],
            [50.69888844706938, 25.722649164565517],
            [51.013352, 26.061055368361128],
            [51.26332121452509, 26.187286614871297],
            [51.66081543497074, 25.921886498571297],
            [51.648351328950824, 25.56548705294199],
            [51.66110504589097, 24.82374325596744],
            [51.40158403204796, 24.626369021507244],
            [51.413705089665314, 24.597046300484486],
            [51.367183657728916, 24.52400378361698],
            [51.31519577204647, 24.48729058700821],
            [51.10204809978889, 24.47076993082091],
            [51.00255575612453, 24.498415427534347],
            [50.93037774053673, 24.54262713463487],
            [50.87327779561088, 24.65098332357006],
            [50.806061432878025, 24.755661681318312],
          ],
        ],
        type: 'Polygon',
      },
    },
    {
      type: 'Feature',
      properties: {
        name: 'United Arab Emirates',
        code: 'AE',
        group: 'Countries',
      },
      geometry: {
        coordinates: [
          [
            [51.57844495984096, 24.396813956085964],
            [51.815072779181264, 24.30475615145905],
            [51.812264217768934, 24.050434978581126],
            [52.564628343978946, 24.256954571199493],
            [53.404007, 24.151317],
            [53.87102178376775, 24.360166354263427],
            [54.63720364710999, 24.82985028354467],
            [55.348899674051964, 25.498174873460943],
            [55.82493209903414, 25.98125711273078],
            [56.196683669069024, 26.086330149359654],
            [56.18505398008287, 25.631784696252293],
            [56.29444038663233, 25.637026860993842],
            [56.37192178461808, 25.599506675691615],
            [56.39250147330631, 25.321046677998247],
            [56.396847, 24.924732],
            [56.11218253616324, 24.706940918404328],
            [55.88164784025553, 24.940926920413],
            [55.82936189312703, 24.216643133253527],
            [55.967701628703765, 24.23747759614166],
            [56.032714494348205, 24.058786876415905],
            [55.523867896224814, 23.939080985370975],
            [55.60366593788696, 23.627145610067192],
            [55.25256325874401, 23.107668143950953],
            [55.21479169531805, 22.707762519176068],
            [55.135370315298395, 22.63061637714463],
            [52.582269925763626, 22.937998042614808],
            [51.590205603552995, 24.125847827138404],
            [51.59072718426361, 24.25395819626383],
            [51.57844495984096, 24.396813956085964],
          ],
        ],
        type: 'Polygon',
      },
    },
  ],
};
